import React from "react"

import Layout from "../../components/layout-static"
import Seo from "../../components/seo"

import { Link } from "gatsby"

const Page = () => {
  const metadata = {
    title: "FATAL ACCIDENT ATTORNEY CONNECTICUT",
    heroH1: "FATAL ACCIDENT ATTORNEY CONNECTICUT",
    heroH2: "When experience, integrity, and results matter.",
  }
  return (
    <Layout metadata={metadata}>
      <Seo title={metadata.title} />
      <div
        id="content"
        className="flex flex-wrap container mx-auto px-4 xl:px-0"
      >
        <div className="w-full pt-8 xl:pt-0">
          <div className="mb-16">
            <h2>
              FATAL ACCIDENT ATTORNEY HELPING YOU RECOVER FROM THE ULTIMATE LOSS
            </h2>
            <p className="mb-8">
              A fatal accident that is the result of someone’s negligence or
              fault is referred to by the legal profession as a wrongful death.
              Bartlett Legal Group is a wrongful death law firm and wrongful
              death is our core business. Contact our fatal accident attorney
              today for a free, no obligation consultation. Please click here to
              see more helpful questions about wrongful death cases.
            </p>
            <p className="mb-8">
              Fatal accident lawsuits can result from many types of accidents
              such as:
            </p>
            <ul className="list-disc list-outside ml-6 mb-8">
              <li>Negligence</li>
              <li>Recklessness</li>
              <li>Speeding</li>
              <li>Driving under the influence (DUI)</li>
              <li>Auto defects / Manufacturing defects</li>
              <li>Distracted driving such as texting</li>
              <li>Aggressive driving or road rage</li>
              <li>Unsafe road conditions such as a reported pot-hole.</li>
              <li>Unmarked construction areas</li>
            </ul>
            <h2>
              Connecticut traffic deaths in 2016 rose to their highest level in
              the past four years. Many were the result of the nine reasons
              listed above.
            </h2>
            <ul className="list-disc list-outside ml-6 mb-8">
              <li>2013: 278 deaths</li>
              <li>2014: 245 deaths</li>
              <li>2015: 273 deaths</li>
              <li>2016: 301 deaths</li>
            </ul>
            <p className="mb-8">
              For more information about car accident lawsuits and answers{" "}
              <Link to="/car-accident-lawyer/">please click here</Link>.
            </p>
            <Link to="/contact-us/">
              <button className="mb-8 mx-auto cta-button-green">
                Contact us today for a free confidential consultation
              </button>
            </Link>
            <h2>
              Fatal Boating Accident or Drowning due to negligence or error of
              another.
            </h2>
            <p className="mb-8">
              Truck Fatal Accident or truck car fatal accidents present many
              different aspects to consider and research such as:
            </p>
            <ul className="list-disc list-outside ml-6 mb-8">
              <li>
                Was the driver falling asleep due to excessive hours logged?
              </li>
              <li>Was the truck overloaded?</li>
              <li>
                Was the speed excessive for conditions? When did the truck leave
                its origination point compared to the time of the accident?
              </li>
              <li>
                What information is contained in the truck’s Electronic Control
                Module (ECM) and does that conflict with the driver’s version of
                events?
              </li>
              <li>
                Was the accident caused by a mechanical defect or worn part that
                should have been replaced? We have experts that will investigate
                this.
              </li>
              <li>
                Trucks have much more mass than cars, especially when fully
                loaded. This usually results in more serious injuries to the
                occupants of the car in a truck / car accident.
              </li>
              <li>
                Has the truck company been cited for safety issues in the past?
              </li>
              <li>
                Was there driver negligence that caused a jack-knife or
                roll-over?
              </li>
              <li>Was the truck driver driving aggressively?</li>
            </ul>
            <h2>Motorcycle Fatal Accident</h2>
            <p className="mb-8">
              Our attorneys are motorcycle riders and know firsthand the dangers
              associated with riding. We know what to look for that may have
              contributed to the accident. We understand that car drivers often
              don’t see us. This does not release them of liability.{" "}
              <Link to="/ct-wrongful-death/motorcycle-accidents/">
                For more information please click here
              </Link>
              .
            </p>
            <h2>Medical Errors Resulting in Death / Medical Malpractice</h2>
            <p className="mb-8">
              Bartlett Legal Group is very fortunate to have a certified legal
              nurse with over forty years of experience. She is invaluable when
              determining the cause and the fault of the death.{" "}
              <Link to="/ct-wrongful-death/connecticut-medical-malpractice/">
                For more information about Medical Malpractice please click here
              </Link>
              .
            </p>
            <h2>Workplace Fatality or on the job fatal injury</h2>
            <p className="mb-8">
              OSHA has made the workplace much safer over the years.
              Unfortunately, there are still 4500 workplace deaths a year in the
              U.S. That’s over 12 work-related deaths a day. We still see too
              many cases relating to unsafe working conditions. This is often
              due to cost-cutting by companies. These employers need to be held
              accountable.
            </p>
            <p className="mb-8">
              Dram Shop Liability – When someone dies as a result of being
              served too much liquor and a death occurs the establishment
              serving the liquor may be held responsible. This may also be true
              if the intoxication caused a fatality as the result of a car
              accident. For more information about alcohol-related deaths please
              click here.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
